let useFilenameBusting = false;

export function setUseFilenameBusting(value) {
	useFilenameBusting = !!value;
}

export default function cacheBustUrl(url, cacheBustString) {
	cacheBustString = cacheBustString || '' + (+new Date());

	if (useFilenameBusting) {
		const lastDotIndex = url.lastIndexOf('.');

		if (lastDotIndex > -1) {
			return url.substring(0, lastDotIndex) + '.' + cacheBustString + url.substring(lastDotIndex);
		}
	}

	return url + '?' + cacheBustString;
}
