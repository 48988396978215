import {FONT_SIZE_DEFAULT, LINE_HEIGHT_DEFAULT} from '../_variables';

const COLLAPSED_LINES = 3;
// const UN_COLLAPSED_LINES = 21;

const MIN_HEIGHT = FONT_SIZE_DEFAULT * LINE_HEIGHT_DEFAULT * COLLAPSED_LINES;

export function render() {
	document.querySelectorAll('.jcbs-collapsible-info').forEach((elem) => {
		const innerElem = elem.querySelector('.jcbs-collapsible-info__content-inner');
		if (innerElem && innerElem.clientHeight < MIN_HEIGHT) {
			elem.classList.add('jcbs-collapsible-info--disabled');
		}
	});
}
