import {init as initLazyImageLoading} from './modules/lazy-image-loading';
import './modules/paragraph';
import './modules/cookie-banner';

import {render as renderEmailDecrypt} from './modules/email-decrypt';
import {render as renderCollapsibleInfo} from './modules/collapsible-info';
import {render as renderCollapsible} from './modules/collapsible';
import {render as renderFigureZoom} from './modules/figure-zoom';
import {render as renderSidebar} from './modules/sidebar';
import {render as renderIframe} from './modules/iframe';
import {render as renderModalInfoBox} from './modules/modal-info-box';

renderEmailDecrypt();
renderFigureZoom();
renderCollapsibleInfo();
renderCollapsible();
renderSidebar();
renderIframe();
renderModalInfoBox();

try {
	require('./vendor/sp-common'); // Sitepark code
} catch (error) {
	console.error('Sitepark JS error', error);
}

// init img lazy loading after everything else, allowing custom handling to take place
initLazyImageLoading();
