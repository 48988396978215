/// NOTE: Keep in sync with src/scss/_variables.scss !

const REM_IN_PX = parseFloat(window.getComputedStyle(document.documentElement).fontSize);

export const BREAKPOINTS = {
	desktopAndUp: 1024,
	tableAndUp: 768,
	mobile: [0, 767],
	sidebarFixed: 920,
	sidebarOffCanvas: [0, 919],
	siteMaxWidth: 1440,
};

export const FONT_SIZE_DEFAULT = 1.6 * REM_IN_PX;

export const LINE_HEIGHT_DEFAULT = 23/16;

export const CLASS_NAME_JS_HIDDEN = 'jcbs--js-hidden';
