import focusableSelectors from 'focusable-selectors';


/*
    <div class="jcbs-modal" id="modal-1" aria-hidden="true">
      <div class="jcbs-modal__backdrop" tabindex="-1" data-jcbs-modal-close>
        <div
          class="jcbs-modal__dialog jcbs-dialog"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-1-title"
        >
          <header class="jcbs-dialog__header">
            <h2 class="jcbs-dialog__headline" id="modal-1-title">
								Informationen zum Bürgergeld
            </h2>

            <button
              type="button"
              class="jcbs-dialog__close-button"
              aria-label="Close modal"
              data-jcbs-modal-close
						>
						<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40" class="jcbs-icon" aria-hidden="true" role="img">
							<path d="M21.414 20l7.071 7.071-1.414 1.414L20 21.415l-7.071 7.07-1.414-1.414L18.585 20l-7.07-7.071 1.414-1.414L20 18.585l7.071-7.07 1.414 1.414z" fill-rule="evenodd"/>
						</svg>
					</button>
          </header>

          <div class="jcbs-dialog__content">
							<div data-sp-table="" class="jcbs-paragraph"><p>Ab 01.01.2023 soll das Bürgergeld die bisherige Grundsicherung ersetzen.</p><p>Sobald das Gesetz in Kraft tritt, werden wir Sie hier informieren. Bitte sehen Sie bis dahin von Rückfragen ab.</p><p>Wichtig für Sie zu wissen: Wenn Sie bereits Geldleistungen vom Jobcenter beziehen, brauchen Sie für das Bürgergeld keinen neuen Antrag zu stellen.</p></div>
          </div>
        </div>
      </div>
    </div>
 */

class Modal {
	constructor({
		targetModal,
		triggers = [],
		onShow = () => {},
		onClose = () => {},
		openTrigger = 'data-jcbs-modal-trigger',
		closeTrigger = 'data-jcbs-modal-close',
		openClass = 'is-open',
		disableScroll = false,
		awaitCloseAnimation = false,
		awaitOpenAnimation = false,
	}) {
		// Save a reference of the modal
		this.modal = document.getElementById(targetModal);

		// Save a reference to the passed config
		this.config = {
			disableScroll: disableScroll,
			openTrigger: openTrigger,
			closeTrigger: closeTrigger,
			openClass: openClass,
			onShow: onShow,
			onClose: onClose,
			awaitCloseAnimation: awaitCloseAnimation,
			awaitOpenAnimation: awaitOpenAnimation,
		};

		// Register click events only if pre binding eventListeners
		if (triggers.length > 0) this.registerTriggers(...triggers);

		// pre bind functions for event listeners
		this.onClick = this.onClick.bind(this);
		this.onKeydown = this.onKeydown.bind(this);
	}

	/**
	 * Loops through all openTriggers and binds click event
	 *
	 * @param {HTMLElement} triggers [Array of node elements]
	 * @returns {void}
	 */
	registerTriggers(...triggers) {
		triggers.filter(Boolean).forEach((trigger) => {
			trigger.addEventListener('click', (event) => this.showModal(event));
		});
	}

	showModal(event = null) {
		this.activeElement = document.activeElement;
		this.modal.setAttribute('aria-hidden', 'false');
		this.modal.classList.add(this.config.openClass);
		this.scrollBehaviour('disable');
		this.addEventListeners();

		if (this.config.awaitOpenAnimation) {
			const handler = () => {
				this.modal.removeEventListener('animationend', handler, false);
				this.setFocusToFirstNode();
			};
			this.modal.addEventListener('animationend', handler, false);
		} else {
			this.setFocusToFirstNode();
		}

		this.config.onShow(this.modal, this.activeElement, event);
	}

	closeModal(event = null) {
		const modal = this.modal;
		this.modal.setAttribute('aria-hidden', 'true');
		this.removeEventListeners();
		this.scrollBehaviour('enable');
		if (this.activeElement && this.activeElement.focus) {
			this.activeElement.focus();
		}
		this.config.onClose(this.modal, this.activeElement, event);

		if (this.config.awaitCloseAnimation) {
			const openClass = this.config.openClass; // <- old school ftw
			this.modal.addEventListener(
				'animationend',
				function handler() {
					modal.classList.remove(openClass);
					modal.removeEventListener('animationend', handler, false);
				},
				false
			);
		} else {
			modal.classList.remove(this.config.openClass);
		}
	}

	closeModalById(targetModal) {
		this.modal = document.getElementById(targetModal);
		if (this.modal) this.closeModal();
	}

	scrollBehaviour(toggle) {
		if (!this.config.disableScroll) return;
		const body = document.querySelector('body');
		switch (toggle) {
			case 'enable':
				Object.assign(body.style, {overflow: ''});
				break;
			case 'disable':
				Object.assign(body.style, {overflow: 'hidden'});
				break;
			default:
		}
	}

	addEventListeners() {
		this.modal.addEventListener('touchstart', this.onClick);
		this.modal.addEventListener('click', this.onClick);
		document.addEventListener('keydown', this.onKeydown);
	}

	removeEventListeners() {
		this.modal.removeEventListener('touchstart', this.onClick);
		this.modal.removeEventListener('click', this.onClick);
		document.removeEventListener('keydown', this.onKeydown);
	}

	/**
	 * Handles all click events from the modal.
	 * Closes modal if a target matches the closeTrigger attribute.
	 *
	 * @param {MouseEvent<HTMLElement>} event Click Event
	 */
	onClick(event) {
		if (
			event.target &&
			(('hasAttribute' in event.target &&
				event.target.hasAttribute(this.config.closeTrigger)) ||
				('parentNode' in event.target &&
					event.target.parentNode.hasAttribute(
						this.config.closeTrigger
					)))
		) {
			event.preventDefault();
			event.stopPropagation();
			this.closeModal(event);
		}
	}

	onKeydown(event) {
		if (event.keyCode === 27) this.closeModal(event); // esc
		if (event.keyCode === 9) this.retainFocus(event); // tab
	}

	getFocusableNodes() {
		const nodes = this.modal.querySelectorAll(focusableSelectors);
		return Array(...nodes);
	}

	/**
	 * Tries to set focus on a node which is not a close trigger
	 * if no other nodes exist then focuses on first close trigger
	 */
	setFocusToFirstNode() {
		const focusableNodes = this.getFocusableNodes();

		// no focusable nodes
		if (focusableNodes.length === 0) return;

		// remove nodes on whose click, the modal closes
		// could not think of a better name :(
		const nodesWhichAreNotCloseTargets = focusableNodes.filter(
			(node) => !node.hasAttribute(this.config.closeTrigger)
		);

		if (nodesWhichAreNotCloseTargets.length > 0)
			nodesWhichAreNotCloseTargets[0].focus();
		if (nodesWhichAreNotCloseTargets.length === 0)
			focusableNodes[0].focus();
	}

	retainFocus(event) {
		let focusableNodes = this.getFocusableNodes();

		// no focusable nodes
		if (focusableNodes.length === 0) return;

		/**
		 * Filters nodes which are hidden to prevent
		 * focus leak outside modal
		 */
		focusableNodes = focusableNodes.filter(
			(node) => node.offsetParent !== null
		);

		if (!this.modal.contains(document.activeElement)) {
			focusableNodes[0].focus();
		} else {
			const focusedItemIndex = focusableNodes.indexOf(
				document.activeElement
			);

			if (event.shiftKey && focusedItemIndex === 0) {
				focusableNodes[focusableNodes.length - 1].focus();
				event.preventDefault();
			}

			if (
				!event.shiftKey &&
				focusableNodes.length > 0 &&
				focusedItemIndex === focusableNodes.length - 1
			) {
				focusableNodes[0].focus();
				event.preventDefault();
			}
		}
	}
}

/**
 * Modal prototype ends.
 * Here on code is responsible for detecting and
 * auto binding event handlers on modal triggers
 */

// Keep a reference to the opened modal
let activeModal = null;

/**
 * Generates an associative array of modals, and it's
 * respective triggers
 *
 * @param  {Array<HTMLElement>} triggers     An array of all triggers
 * @param  {string} triggerAttr The data-attribute which triggers the module
 * @returns {Record<string, HTMLElement>} trigger map
 */
const generateTriggerMap = (triggers, triggerAttr) => {
	const triggerMap = {};

	triggers.forEach((trigger) => {
		const targetModal = trigger.attributes[triggerAttr].value;
		if (triggerMap[targetModal] === undefined) triggerMap[targetModal] = [];
		triggerMap[targetModal].push(trigger);
	});

	return triggerMap;
};

/**
 * Binds click handlers to all modal triggers
 *
 * @param  {object} config [description]
 * @returns {void}
 */
export const init = (config) => {
	// Create a config object with default openTrigger
	const options = {
		openTrigger: 'data-jcbs-modal-trigger',
		...config,
	};

	// Collects all the nodes with the trigger
	const triggers = [...document.querySelectorAll(`[${options.openTrigger}]`)];

	// Makes a mappings of modals with their trigger nodes
	const triggerMap = generateTriggerMap(triggers, options.openTrigger);

	// For every target modal creates a new instance
	for (const key in triggerMap) {
		if (Object.hasOwn(triggerMap, key)) {
			const value = triggerMap[key];
			options.targetModal = key;
			options.triggers = [...value];
			activeModal = new Modal(options);
		}
	}
};

/**
 * Shows a particular modal
 *
 * @param  {string} targetModal [The id of the modal to display]
 * @param  {object} [config={}] [The configuration object to pass]
 * @returns {void}
 */
export const show = (targetModal, config) => {
	const options = config || {};
	options.targetModal = targetModal;

	// clear events in case previous modal wasn't closed
	if (activeModal) activeModal.removeEventListeners();

	// stores reference to active modal
	activeModal = new Modal(options); // eslint-disable-line no-new
	activeModal.showModal();
};

/**
 * Closes the active modal
 *
 * @param  {string} targetModal [The id of the modal to close]
 * @returns {void}
 */
export const close = (targetModal) => {
	if (targetModal) {
		activeModal.closeModalById(targetModal);
	} else {
		activeModal.closeModal();
	}
};
