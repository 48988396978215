// noinspection NonAsciiCharacters
export default {
	'mejs.video-player': 'Video player',
	'mejs.audio-player': 'Audio player',
	'mejs.play': 'Play',

	'lightbox.image': 'The image',
	'lightbox.loading_error': 'could not be loaded.',

	'cookie_banner.dismiss': 'OK',
	'cookie_banner.message': 'This website uses cookies to ensure you get the best experience on our website.',
	'cookie_banner.link_text': 'Learn more',

	'sidebar.Close Menu': 'Close Menu',
	'sidebar.Close Submenu': 'Close Submenu',
	'sidebar.Close main navigation': 'Close main navigation',
	'sidebar.Open main navigation': 'Open main navigation',
	'sidebar.Jump to main navigation': 'Jump to main navigation',
	'sidebar.Open search': 'Open search',
	'sidebar.Close search': 'Close search',
	'sidebar.Hits by relevance': 'Hits by relevance',
	'sidebar.No hits': 'No hits',
	'sidebar:Error occurred while searching': 'Error occurred while searching',
};
