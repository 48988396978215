import fetchPonyfill from 'fetch-ponyfill';

export const {fetch, Request, Response, Headers} = fetchPonyfill({Promise: Promise});

export function fetchTextWithAbort(request, onSuccess, onError) {
	// TODO: abort the actual request

	fetch(request)
		.catch(err => {
			onError(err);
			// explicitlly returning `undefined`, not just re-return the returned value of `onError`
			return undefined;
		})
		.then(response => {
			// ignore `undefined` coming from `catch`
			if (response === undefined) {
				return;
			}

			if (response.status === 0 || response.ok) {
				response.text()
					.catch(err => {
						onError(err);
						return undefined;
					})
					.then(text => {
						if (text !== undefined) {
							onSuccess(text);
						}
					});
			} else {
				onError(response.status);
			}
		});

	return {
		abort: function () {
			onSuccess = () => undefined;
			onError = () => undefined;
		},
	};
}

export function fetchJsonPromise(request) {
	return fetch(request)
		.then(res => {
			// what does "http status code == 0" mean in this case?
			if (res.status === 0 || res.ok) {
				return res.json();
			} else {
				throw new Error(`Unsuccessful request (status: "${res.statusText}") for "${request}"`);
			}
		});
}

export function fetchJsonWithAbort(request, onSuccess, onError) {
	// TODO: abort the actual request

	fetch(request)
		.catch(err => {
			onError(err);
			// explicitlly returning `undefined`, not just re-return the returned value of `onError`
			return undefined;
		})
		.then(response => {
			// ignore `undefined` coming from `catch`
			if (response === undefined) {
				return;
			}

			if (response.status === 0 || response.ok) {
				response.json()
					.catch(err => {
						onError(err);
						return undefined;
					})
					.then(text => {
						if (text !== undefined) {
							onSuccess(text);
						}
					});
			} else {
				onError(response.status);
			}
		});

	return {
		abort: function () {
			onSuccess = () => undefined;
			onError = () => undefined;
		},
	};
}
