//import iFrameResize from 'iframe-resizer/js/iframeResizer';

const DEFAULTS = {
	options: {enabled: true},
	//seamless: false,
	//checkOrigin: true,
};
const DATA_ATTRIBUTE_OPTIONS = 'data-sp-iframe';

function addBreakpointListener(mediaQuery, callback) {
	const mql = window.matchMedia(mediaQuery);

	function check(breakpoint) {
		if (breakpoint.matches === true) {
			callback();
		}
	}

	mql.addListener(check);
	check(mql); // initial check
}

//function handleResizeEvent(data) {
//	data.iframe.setAttribute('scrolling', 'no');
//}

function setStatus(element, enabled = false) {
	if (enabled) {
		element.classList.remove('jcbs-iframe--disabled');
		element.classList.add('jcbs-iframe--enabled');
	} else {
		element.classList.add('jcbs-iframe--disabled');
		element.classList.remove('jcbs-iframe--enabled');
	}
}

/**
 * @param {HTMLElement} element element
 */
function plugin(element) {
	//const iframeElement = element.getElementsByTagName('iframe')[0];
	const options = JSON.parse(element.getAttribute(DATA_ATTRIBUTE_OPTIONS));
	const {responsive, options: {enabled: defaultEnabled}} = {...DEFAULTS, ...options}; // , seamless, checkOrigin

	setStatus(element, defaultEnabled);
	if (Array.isArray(responsive)) {
		responsive.forEach(function ({breakpoint: {mediaQuery, options: {enabled: enabledWithBreakpoint}}}) {
			addBreakpointListener(mediaQuery, function handleBreakpoint() {
				setStatus(element, enabledWithBreakpoint);
			});
		});
	}

	//if (seamless) {
	//	iFrameResize({
	//		checkOrigin: checkOrigin,
	//		autoResize: true,
	//		scrolling: true,
	//		onResized: handleResizeEvent,
	//		resizedCallback: handleResizeEvent, // deprecated, for legacy support
	//	}, iframeElement);
	//}
}

export function render() {
	document.querySelectorAll('.js-jcbs-iframe').forEach(plugin);
}
