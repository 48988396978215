import 'cookieconsent';

import {translate} from '../helpers/i18n';

window.cookieconsent.initialise({
	palette: {
		popup: {
			background: '#ffffff',
		},
		button: {
			background: '#0070b8',
		},
	},
	content: {
		message: translate('cookie_banner.message'),
		dismiss: translate('cookie_banner.dismiss'),
		link: translate('cookie_banner.link_text'),
		href: 'https://jobcenter.braunschweig.de/datenschutz.html', // TODO
	},
	position: 'bottom-left',
	static: false,
	onPopupOpen: function () {
		const ccWindow = document.querySelector('.cc-window');
		const footer = document.querySelector('.jcbs-site-footer');
		if (ccWindow && footer) {
			footer.style.paddingBottom = `${ccWindow.clientHeight}px`;
		}
	},
	onPopupClose: function () {
		const footer = document.querySelector('.jcbs-site-footer');
		if (footer) {
			footer.style.paddingBottom = null;
		}
	},
});
