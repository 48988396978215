import {show} from './modal';

const DISMISSED_STORAGE_KEY = 'jcbs-modal-info-box-dismissed';

function setDismissed(key) {
	if ('sessionStorage' in window) {
		sessionStorage.setItem(DISMISSED_STORAGE_KEY, key);
	}
}

function wasDismissed(key) {
	if ('sessionStorage' in window) {
		return sessionStorage.getItem(DISMISSED_STORAGE_KEY) === key;
	}

	return false;
}

/**
 * @param {HTMLElement} element modal infobox element
 */
function plugin(element) {
	const key = element.getAttribute('data-jcbs-modal-key') || 'default';
	if (!wasDismissed(key)) {
		show(element.id, {
			onClose: () => {
				setDismissed(key);
			},
		});
	}
}

export function render() {
	document.querySelectorAll('.js-jcbs-modal-info-box').forEach(plugin);
}
