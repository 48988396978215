import lazySizes from 'lazysizes';
import 'lazysizes/plugins/noscript/ls.noscript';

const config = Object.assign(
	lazySizes.cfg,
	{
		lazyClass: 'js-jcbs-lazy-image-loading',
		deferredClass: 'js-jcbs-lazy-image-loading--deferred',
		preloadClass: 'js-jcbs-lazy-image-loading--preload',
		loadedClass: 'js-jcbs-lazy-image-loading--loaded',
		loadingClass: 'js-jcbs-lazy-image-loading--loading',
		errorClass: 'js-jcbs-lazy-image-loading--error',
	}
);

export function init() {
	lazySizes.init();
}

export function getConfig() {
	return config;
}

export function setConfig(newConfig) {
	Object.assign(config, newConfig);
}

export function findLazyLoadingImages(containerElement) {
	return [...containerElement.querySelectorAll(`.${config.lazyClass}`)];
}

export function findAllLazyLoadingImages(containerElement) {
	const CLASS_NAMES = [
		'lazyClass',
		'deferredClass',
		'preloadClass',
		'loadedClass',
		'loadingClass',
	];
	const classes = CLASS_NAMES.map(k => `.${config[k]}`).join();
	return [...containerElement.querySelectorAll(classes)];
}

export function findDeferredLazyLoadingImages(containerElement) {
	return [...containerElement.querySelectorAll(`.${config.deferredClass}`)];
}

/**
 * @param {Array} imageElements imageElements to defer lazy loading
 */
export function deferLazyLoadingImages(imageElements) {
	const {deferredClass, lazyClass} = config;

	imageElements.forEach(imageElement => {
		imageElement.classList.add(deferredClass);
		imageElement.classList.remove(lazyClass);
	});
}

/**
 * @param {Array} imageElements imageElements to undefer
 */
export function undeferLazyLoadingImages(imageElements) {
	const {deferredClass, lazyClass} = config;

	imageElements.forEach(imageElement => {
		imageElement.classList.remove(deferredClass);
		imageElement.classList.add(lazyClass);
	});
}

/**
 * @param {Array} imageElements imageElements to unveil
 */
export function unveilLazyLoadingImages(imageElements) {
	undeferLazyLoadingImages(imageElements);
	imageElements.forEach(lazySizes.loader.unveil);
}
