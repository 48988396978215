import passiveEventListener from '@neonaut/lib-js/es/dom/events/passive-event-listener';
import setAriaAttribute from '@neonaut/lib-js/es/dom/access/set-aria-attribute';
import waitForTransitionEnd from '@neonaut/lib-js/es/dom/transition/wait-for-transition-end';
//import getOffsetTop from '@neonaut/lib-js/es/dom/access/get-offset-top';
import getViewportWidth from '@neonaut/lib-js/es/dom/access/get-viewport-width';

//import {CLASS_NAME_JS_HIDDEN} from '../_variables';
//import {
//	deferLazyLoadingImages,
//	findDeferredLazyLoadingImages,
//	findLazyLoadingImages,
//	unveilLazyLoadingImages
//} from './lazy-image-loading';

const SELECTOR_CONTAINER = '.js-jcbs-collapsible';
const SELECTOR_TRIGGER = '.js-jcbs-collapsible-trigger';
const SELECTOR_CONTENT = '.js-jcbs-collapsible-content';
const SELECTOR_CONTENT_WRAPPER = '.js-jcbs-collapsible-content-wrapper';

const defaultOptions = {
	expandTransitionDuration: 1000,
	collapseTransitionDuration: 1000,
	heightOffset: (transition) => (transition === 'collapse' ? 0 : 80),
	scrollOffset: (transition) => (transition === 'collapse' ? 40 : (getViewportWidth() > 600 ? 80 : 40)), // TODO: Refactor breakpoints
};

export class Collapsible {
	constructor(containerElement, options = {}) {
		this.options = {...defaultOptions, ...options};
		this.containerElement = containerElement;
		this.state = {transitioning: false, ...this.getInitialStateFromDom()};

		this.updateContentElementClasses();
		this.updateAriaAttributes();

		this.containerElement.addEventListener('click', this.triggerClickHandler, passiveEventListener);

		const triggerElement = this.getTriggerElement();
		this.triggerClickHandler = e => this.handleTriggerClick(e);
		this.triggerKeyPressHandler = e => this.handleTriggerKeyPress(e);
		triggerElement.addEventListener('click', this.triggerClickHandler, passiveEventListener);
		triggerElement.addEventListener('keypress', this.triggerKeyPressHandler);

		this.windowLocationHashChangeHandler = e => this.handleWindowLocationHashChange(e);
		window.addEventListener('hashchange', this.windowLocationHashChangeHandler, passiveEventListener);

		if (this.isLocationHashMatching()) {
			this.scrollContainerElementIntoView();
		}
	}

	static createInstance(containerElement, options = {}) {
		return new Collapsible(containerElement, options);
	}

	getTriggerElement() {
		return this.containerElement.querySelector(SELECTOR_TRIGGER);
	}

	getContentElement() {
		return this.containerElement.querySelector(SELECTOR_CONTENT);
	}

	getContentWrapperElement() {
		return this.containerElement.querySelector(SELECTOR_CONTENT_WRAPPER);
	}

	isLocationHashMatching() {
		const triggerElement = this.getTriggerElement();
		return window.location.hash === `#${triggerElement.getAttribute('data-jcbs-section-id')}`;
	}

	getInitialStateFromDom() {
		return {
			expanded: this.isLocationHashMatching() || this.getTriggerElement().getAttribute('aria-expanded') === 'true',
		};
	}

	getScrollOffset(transition) {
		return typeof this.options.scrollOffset === 'function' ?
			this.options.scrollOffset(transition) :
			+this.options.scrollOffset;
	}

	getHeightOffset(transition) {
		return typeof this.options.heightOffset === 'function' ?
			this.options.heightOffset(transition) :
			+this.options.heightOffset;
	}

	scrollContainerElementIntoView() {
		let top = (window.scrollY || window.pageYOffset) + this.containerElement.getBoundingClientRect().top - this.getScrollOffset('expand');
		top = top < 0 ? 0 : top;

		window.scrollTo({top: top, left: 0, behavior: 'smooth'});
	}

	updateContentElementClasses() {
		const isExpanded = this.state.expanded;
		const isTransitioning = this.state.transitioning;
		const contentElement = this.getContentElement();
		contentElement.classList[isTransitioning ? 'add' : 'remove']('jcbs-collapsible__content--transitioning');
		contentElement.classList[isExpanded ? 'add' : 'remove']('jcbs-collapsible__content--expanded');
		contentElement.classList[isExpanded ? 'remove' : 'add']('jcbs-collapsible__content--collapsed');
	}

	updateAriaAttributes() {
		setAriaAttribute(this.getTriggerElement(), 'expanded', this.state.expanded ? 'true' : 'false');
		setAriaAttribute(this.getContentElement(), 'hidden', this.state.expanded ? 'false' : 'true');
	}

	expand() {
		this.toggle('expand');
	}

	collapse() {
		this.toggle('collapse');
	}

	toggle(transition = null) {
		transition = transition || (this.state.expanded ? 'collapse' : 'expand');
		this.state.transitioning = true;
		this.state.expanded = transition === 'expand';

		const contentElement = this.getContentElement();
		const contentWrapperElement = this.getContentWrapperElement();

		// Transition
		const height = contentWrapperElement.offsetHeight + this.getHeightOffset(transition);
		contentElement.style.maxHeight = `${height}px`;

		this.updateAriaAttributes();

		setTimeout(() => {
			this.updateContentElementClasses();
			if (transition === 'expand') {
				this.scrollContainerElementIntoView();
			} else {
				window.scrollBy({top: -this.getScrollOffset('collapse'), left: 0, behavior: 'smooth'});
			}

			waitForTransitionEnd(contentElement, () => {
				this.state.transitioning = false;
				contentElement.style.maxHeight = null;
				this.updateContentElementClasses();
			}, this.options[`${transition}TransitionDuration`]);
		}, 20);
	}

	destruct() {
		const triggerElement = this.getTriggerElement();
		triggerElement.removeEventListener('click', this.triggerClickHandler, passiveEventListener);
		triggerElement.removeEventListener('keypress', this.triggerKeyPressHandler);
		window.removeEventListener('hashchange', this.windowLocationHashChangeHandler, passiveEventListener);
	}

	handleTriggerClick() {
		this.toggle();
	}

	handleTriggerKeyPress(e) {
		if (
			e.keyCode === 13 || // enter
			e.keyCode === 32 // space (bar)
		) {
			e.preventDefault();
			this.toggle();
		}
	}

	handleWindowLocationHashChange() {
		if (this.isLocationHashMatching()) {
			this.expand();
		}
	}
}

export function render() {
	[...document.querySelectorAll(SELECTOR_CONTAINER)].forEach(Collapsible.createInstance);
}
