// TODO: https://www.npmjs.com/package/i18next ?

import de from './i18n/de';
import en from './i18n/en';

const DEFAULT_LANGUAGE = 'en';
let documentLanguage = document.documentElement.lang || DEFAULT_LANGUAGE;

const DICTIONARY = {en: en, de: de};

export function setDocumentLanguage(value) {
	documentLanguage = value;
}

export function getDocumentLanguage() {
	return documentLanguage;
}

export function translate(key, language = documentLanguage) {
	if (!DICTIONARY[language]) {
		language = DEFAULT_LANGUAGE;
	}

	return DICTIONARY[language][key];
}
