// noinspection NonAsciiCharacters
export default {
	'mejs.video-player': 'Video-Player',
	'mejs.audio-player': 'Audio-Player',
	'mejs.play': 'Abspielen',

	'lightbox.image': 'Das Bild',
	'lightbox.loading_error': 'konnte nicht geladen werden.',

	'cookie_banner.dismiss': 'OK',
	'cookie_banner.message': 'Cookies erleichtern die Bereitstellung unserer Dienste. Mit der Nutzung unserer Dienste erklären Sie sich damit einverstanden, dass wir Cookies verwenden.',
	'cookie_banner.link_text': 'mehr',

	'sidebar.Close Menu': 'Menü schließen',
	'sidebar.Close Submenu': 'Untermenü schließen',
	'sidebar.Close main navigation': 'Hauptmenü schließen',
	'sidebar.Open main navigation': 'Hauptmenü öffnen',
	'sidebar.Jump to main navigation': 'Zum Hauptmenü springen',
	'sidebar.Open search': 'Suche öffnen',
	'sidebar.Close search': 'Suche schließen',
	'sidebar.Hits by relevance': 'Treffer nach Relevanz',
	'sidebar.No hits': 'Keine Treffer',
	'sidebar:Error occurred while searching': 'Fehler bei der Suche',
};
