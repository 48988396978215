import SimpleScrollBar from 'simplebar';
import throttle from 'lodash/throttle';

import setRole from '@neonaut/lib-js/es/dom/access/set-role';
import setAriaAttribute from '@neonaut/lib-js/es/dom/access/set-aria-attribute';
import passiveEventListener from '@neonaut/lib-js/es/dom/events/passive-event-listener';
import waitForTransitionEnd from '@neonaut/lib-js/es/dom/transition/wait-for-transition-end';
import childrenWithClass from '@neonaut/lib-js/es/dom/traverse/children-with-class';
import descendantsWithClass from '@neonaut/lib-js/es/dom/traverse/descendants-with-class';

import {fetchJsonPromise} from '../../helpers/fetch';
import breakpoint from '../../helpers/breakpoint';
import {translate} from '../../helpers/i18n';
import setTabFocusable from '../../helpers/set-tab-focusable';

import {init as initSearchPanel} from './search-panel';

const SELECTOR_FOCUSABLE = 'input, select, textarea, button, label, a[href], .focusable';

const PREFIX = 'jcbs-sidebar-';
const VIEW_SEARCH = 'search';
const VIEW_MENU = 'menu';
const DEFAULT_VIEW = VIEW_MENU;

const TRANSITION_DURATION = 400;
const TRANSITION_FALLBACK_DURATION = 1.1 * TRANSITION_DURATION;
const ASYNC_DELAY = 25;

let uniqueId = 0;
const p = a => PREFIX + a; // add prefix
const pp = (additionalPrefix, a) => `${additionalPrefix}-${PREFIX}${a}`;
const dP = a => pp('data', a); // add data prefix
const pId = () => p(uniqueId++);
const affixSelectors = (selectors, pre = '', post = '') =>
	selectors
		.split(',')
		.map(selector => selector.trim())
		.map(selector => `${pre}${selector}${post}`)
		.join(',');

function createTabbableButton() {
	const buttonElement = document.createElement('button');
	buttonElement.setAttribute('type', 'button');
	buttonElement.setAttribute('disabled', 'disabled');
	setAriaAttribute(buttonElement, 'hidden', true);
	setRole(buttonElement, 'presentation');

	return buttonElement;
}

export default class Sidebar {
	constructor(baseElement) {
		this.panelDrops = [];

		// constructor
		this.init(baseElement);
	}

	init(baseElement) {
		this.baseElement = baseElement;
		this.state = {
			initialized: false,
			opened: false,
			view: undefined,
			id: this.baseElement.getAttribute('id') || pId(),
			lazyLoadedPanels: {},
		};

		this.initBaseElement();
		this.initPageBlocker();

		// open panel of selected menu item OR fall back to the first menu panel
		const panelElement = childrenWithClass(this.panelsContainerElement, p('panel--opened'))[0];
		if (panelElement) {
			this.initialPanelId = panelElement.getAttribute('id');
			Sidebar.initPanel(panelElement);
		}

		initSearchPanel(this);
		this.initGlobalClickListener();

		this.setView(DEFAULT_VIEW);
		this.state.initialized = true;
		updateToggleElements(this.state);

		window.addEventListener(
			'resize',
			throttle(
				() => {
					this.updateVisibility();
				},
				150
			),
			{passive: true}
		);
	}

	static render(selector, ...args) {
		return [...document.querySelectorAll(selector)]
			.map(baseElement => new Sidebar(baseElement, ...args))
			.filter(a => !!a);
	}

	initPageBlocker() {
		const pageBlockerElement = document.createElement('div');
		pageBlockerElement.classList.add(p('page-blocker'));
		document.body.appendChild(pageBlockerElement);

		const pageBlockerTouchListener = e => {
			e.stopPropagation();
			this.close();
		};

		pageBlockerElement.addEventListener('touchstart', pageBlockerTouchListener, passiveEventListener);
		pageBlockerElement.addEventListener('touchmove', pageBlockerTouchListener, passiveEventListener);

		const pageBlockerMouseListener = e => {
			e.preventDefault();
			this.close();
		};
		pageBlockerElement.addEventListener('mousedown', pageBlockerMouseListener);
	}

	initBaseElement() {
		this.updateVisibility();

		this.panelsContainerElement = this.baseElement.getElementsByClassName(p('panels'))[0];

		// Open if url hash equals menu id (useful when user clicks the hamburger icon before the menu is created)
		const hash = window.location.hash;
		if (hash && hash.slice(1) === this.state.id) {
			setTimeout(() => this.open(), 1000);
		}

		// Prevent tabbing outside an offcanvas menu
		this.baseElement.addEventListener('scroll', e => e.stopPropagation());
		this.baseElement.addEventListener('touchstart', e => e.stopPropagation());
		this.baseElement.addEventListener('touchmove', e => e.stopPropagation());

		// Prevent tabbing outside an offcanvas menu
		this.baseElement.addEventListener('focusin', ({target}) => {
			if (this.state.opened && target.classList.contains(p('tabend'))) {
				descendantsWithClass(target.parentNode, p('tabstart'))[0].focus();
			}
		});

		// Enable keyboard navigation
		const startButtonElement = createTabbableButton();
		startButtonElement.classList.add(p('tabstart'));
		this.baseElement.prepend(startButtonElement);

		const endButtonElement = createTabbableButton();
		endButtonElement.classList.add(p('tabend'));
		this.baseElement.append(endButtonElement);

		// Enhanced keyboard navigation
		this.baseElement.addEventListener('keydown', e => {
			switch (e.keyCode) {
				// Backspace
				//  -> close submenu with
				case 8: {
					this.openParentPanel();
					break;
				}

				// Escape
				case 27: {
					this.close();
					break;
				}

				// Arrow down/Arrow up
				case 40:
				case 38:
					e.stopPropagation();
					break;

				// ignore the rest
				default:
			}
		});
	}

	initGlobalClickListener() {
		document.body.addEventListener('click', e => {
			const triggerElement = e.target;

			if (triggerElement.matches(`#${this.state.id} a[${dP('target')}]`)) {
				this.handleClickAnchorInSidebar(e);
			} else if (triggerElement.classList.contains(pp('js', 'toggle'))) {
				this.toggle(VIEW_MENU);
			} else if (triggerElement.classList.contains(pp('js', 'open-menu'))) {
				this.open();
				this.setView(VIEW_MENU);
			} else if (triggerElement.classList.contains(pp('js', 'search-toggle'))) {
				this.toggle(VIEW_SEARCH);
			}
		});
	}

	async handleClickAnchorInSidebar(e) {
		const target = e.target.getAttribute(dP('target'));
		if (!target) {
			return;
		}

		let targetElement = this.panelsContainerElement.querySelector(target);

		// target is not a panel => exit
		if (targetElement && !targetElement.classList.contains(p('panel'))) {
			return;
		}

		// Try to lazy load panel
		if (!targetElement) {
			const panelElement = e.target.closest(`.${p('panel')}[${dP('href')}]`);
			if (!panelElement) {
				return;
			}

			const url = panelElement.getAttribute(dP('href'));
			console.log('handleClickAnchorInSidebar => mus lazy load', url);

			if (!url) {
				return;
			}

			e.preventDefault();

			try {
				this.baseElement.classList.add(p('-loading'));

				await this.loadPanel(url);
				targetElement = this.panelsContainerElement.querySelector(target);

				// target is not a panel => exit
				if (targetElement && !targetElement.classList.contains(p('panel'))) {
					throw new Error('lazy loading did not work');
				}
			} catch (exception) {
				console.error('handleClickAnchorInSidebar => error when lazy loading panel', url, exception);
				window.location.href = e.target.href;
				return;
			} finally {
				this.baseElement.classList.remove(p('-loading'));
			}
		} else {
			e.preventDefault();
		}

		// Open panel
		try {
			this.openPanel(targetElement);
		} catch (err) {
			// empty catch
		}
	}

	setPanelFocus(panelElement) {
		// return if already focus in panel-bar
		if (document.activeElement.matches(affixSelectors(SELECTOR_FOCUSABLE, `#${this.state.id} .${p('panel-bar')} `))) {
			return;
		}

		const firstElement = this.firstFocusablePanelElement(panelElement);

		if (this.state.view !== VIEW_SEARCH) {
			const firstItemElem = this.firstFocusableItemElement(panelElement) || firstElement;
			if (firstItemElem) {
				firstItemElem.focus();
			}
		}

		if (!firstElement) {
			return;
		}

		const lastElement = this.lastFocusablePanelElement(panelElement);
		if (!lastElement) {
			return;
		}

		const handler = function (e) {
			// tab key
			if (e.keyCode === 9) {
				if (e.shiftKey) {
					if (document.activeElement === firstElement) {
						e.preventDefault();
						lastElement.focus();
					}
				} else {
					if (document.activeElement === lastElement) {
						e.preventDefault();
						firstElement.focus();
					}
				}
			}
		};

		document.addEventListener('keydown', handler);
		this.panelDrops.push(() => {
			document.removeEventListener('keydown', handler);

			if (document.activeElement) {
				document.activeElement.blur();
			}
		});
	}

	firstFocusableItemElement(panelElement) {
		// first anchor in *List*
		const firstAnchorInListElement = panelElement.querySelector(`.${p('list')} a[href]:not(.hidden)`);
		if (firstAnchorInListElement) {
			return firstAnchorInListElement;
		}

		// OR first focusable in *Panel*
		const firstFocusableInPanelElement = panelElement.querySelector(affixSelectors(
			SELECTOR_FOCUSABLE, '', ':not(.hidden)'
		));
		if (firstFocusableInPanelElement) {
			return firstFocusableInPanelElement;
		}

		return undefined;
	}

	firstFocusablePanelElement(panelElement) {
		// OR first focusable in *Header*th
		const focusableInHeaderElement = panelElement.querySelector(affixSelectors(
			SELECTOR_FOCUSABLE, `.${p('header')} `, ':not(.hidden)'
		));
		if (focusableInHeaderElement) {
			return focusableInHeaderElement;
		}

		// first anchor in *List*
		const firstAnchorInListElement = panelElement.querySelector(`.${p('list')} a[href]:not(.hidden)`);
		if (firstAnchorInListElement) {
			return firstAnchorInListElement;
		}

		// OR first focusable in *Panel*
		const firstFocusableInPanelElement = panelElement.querySelector(affixSelectors(
			SELECTOR_FOCUSABLE, '', ':not(.hidden)'
		));
		if (firstFocusableInPanelElement) {
			return firstFocusableInPanelElement;
		}

		// OR tab-start button
		const tabStartButtonElement = this.baseElement.querySelector(`${p('tabstart')}`);
		if (tabStartButtonElement) {
			return tabStartButtonElement;
		}

		return null;
	}

	lastFocusablePanelElement(panelElement) {
		// OR last focusable in *Panel*
		const focusableInPanelElements = panelElement.querySelectorAll(affixSelectors(
			SELECTOR_FOCUSABLE, '', ':not(.hidden)'
		));
		if (focusableInPanelElements.length) {
			return focusableInPanelElements[focusableInPanelElements.length - 1];
		}

		// last anchor in *List*
		const anchorsInListElements = panelElement.querySelectorAll(`.${p('list')} a[href]:not(.hidden)`);
		if (anchorsInListElements.length) {
			return anchorsInListElements[anchorsInListElements.length - 1];
		}

		// OR last focusable in *Header*th
		const focusableInHeaderElements = panelElement.querySelectorAll(affixSelectors(
			SELECTOR_FOCUSABLE, `.${p('header')} `, ':not(.hidden)'
		));
		if (focusableInHeaderElements.length) {
			return focusableInHeaderElements[focusableInHeaderElements.length - 1];
		}

		// OR tab-end button
		const tabEndButtonElement = this.baseElement.querySelector(`${p('tabend')}`);
		if (tabEndButtonElement) {
			return tabEndButtonElement;
		}

		return null;
	}

	getOpenedPanelElement() {
		return this.panelsContainerElement.querySelector(`.${p('panel--opened')}`);
	}

	static initPanel(panelElement) {
		// Add scroll behaviour
		const listWrapperElement =
			panelElement.getElementsByClassName('jcbs-sidebar-list-wrapper')[0]
			||
			panelElement.getElementsByClassName('jcbs-sidebar-search__wrapper')[0];
		if (listWrapperElement) {
			// eslint-disable-next-line no-new
			new SimpleScrollBar(listWrapperElement, {autoHide: true});
		}
	}

	openPanel(nextPanel, {animate = true} = {}) {
		if (!nextPanel || nextPanel.classList.contains(p('panel--opened'))) {
			return;
		}

		// Open navigation when navigating inside it once initialized once
		if (this.state.initialized) {
			this.open();
		}

		Sidebar.initPanel(nextPanel);

		const allPanels = childrenWithClass(this.panelsContainerElement, p('panel'));
		const prevOpenedPanels = allPanels.filter(element => element.classList.contains(p('panel--opened')));

		// Reset old parents
		allPanels
			.filter(element => element !== nextPanel)
			.forEach(element => element.classList.remove(p('panel--opened-parent')));

		// Reset old order
		allPanels.forEach(element => element.classList.remove(p('panel--highest')));

		// Open all logical panel parents
		let parentPanelElement = getParentPanelElement(nextPanel);
		while (parentPanelElement) {
			parentPanelElement.classList.add(p('panel--opened-parent'));
			parentPanelElement = getParentPanelElement(parentPanelElement);
		}

		const openPanelStart = () => {
			// hide prev opened panels
			prevOpenedPanels.forEach(e => e.classList.remove(p('panel--opened')));

			// open next panel
			nextPanel.classList.add(p('panel--opened'));

			// fix order of panels
			if (nextPanel.classList.contains(p('panel--opened-parent'))) {
				prevOpenedPanels.forEach(e => e.classList.add(p('panel--highest')));
				nextPanel.classList.remove(p('panel--opened-parent'));
			} else {
				prevOpenedPanels.forEach(e => e.classList.add(p('panel--opened-parent')));
				nextPanel.classList.add(p('panel--highest'));
			}

			this.updateVisibility();
		};

		const openPanelFinish = () => {
			prevOpenedPanels.forEach(openedPanelElement => {
				openedPanelElement.classList.remove(p('panel--highest'));
				openedPanelElement.classList.add('hidden');
			});
			nextPanel.classList.remove(p('panel--highest'));

			if (this.state.initialized) {
				this.setPanelFocus(nextPanel);
			}
		};

		if (!animate) {
			// open without animated transition
			openPanelStart();
			openPanelFinish();
			// Make next panel visible
			nextPanel.classList.remove('hidden');
		} else {
			// Make next panel visible
			nextPanel.classList.remove('hidden');
			// open with animated transition

			// Without the timeout the animation will not work because the element had display: none;
			setTimeout(() => {
				waitForTransitionEnd(nextPanel, openPanelFinish, TRANSITION_FALLBACK_DURATION);
				openPanelStart();
			}, ASYNC_DELAY);
		}

		// Lazy load missing sub panels
		setTimeout(async () => {
			try {
				// openPanel => lazy loading sub panel
				await this.loadPanel(nextPanel.getAttribute(dP('href')));
			} catch (exception) {
				console.error('openPanel => lazy loading sub panel error', exception);
			}
		}, ASYNC_DELAY);
	}

	open() {
		// Already opened -> return
		if (this.state.opened) {
			return;
		}
		this.state.opened = true;

		const scrollBarGap = window.innerWidth - document.documentElement.clientWidth;
		document.body.style.paddingRight = `${scrollBarGap}px`;

		// only apply offset to the sidebar if sidebar is fixed;
		// the gap gets disguised by the transition if sidebar isn't fixed
		if (breakpoint('sidebarFixed')) {
			// we need to halve the gap in "siteMaxWidth"-mode bc the sidebar gets centered
			// (positioned by halving the screen width)
			const sidebarRightOffset = scrollBarGap * (breakpoint('siteMaxWidth') ? 0.5 : 1.0);
			this.baseElement.style.right = `${sidebarRightOffset}px`;
		}

		document.documentElement.classList.add(p('wrapper--is-opening'));
		document.documentElement.classList.add(p('wrapper--is-active'));
		document.documentElement.classList.add(p('wrapper--is-blocking'));

		// Open
		this.baseElement.classList.add(p('-opened'));

		// Without the timeout, the animation won't work because the menu had display: none;
		setTimeout(() => {
			setTimeout(
				() => this.setPanelFocus(this.getOpenedPanelElement()),
				TRANSITION_FALLBACK_DURATION
			);

			// Opening
			this.updateVisibility();
			document.documentElement.classList.remove(p('wrapper--is-opening'));
			document.documentElement.classList.add(p('wrapper--is-opened'));
		}, ASYNC_DELAY);

		updateToggleElements(this.state);
	}

	close() {
		// Already closed -> return
		if (!this.state.opened) {
			return;
		}

		for (const drop of this.panelDrops) {
			drop();
		}
		this.panelDrops = [];

		// Reset view?
		if (this.state.view !== DEFAULT_VIEW) {
			// Reset view
			this.setView(DEFAULT_VIEW);
		} else {
			// Reset menu
			// Reset menu to initial panel
			if (this.initialPanelId) {
				this.openPanel(
					document.getElementById(this.initialPanelId),
					// we only run transitions ("animate") if switching panels on the same view.
					// close is "changing" the view to "default", so if we are already on "default"
					// view we need transitions...
					{animate: true}
				);
			}
		}

		setTimeout(() => {
			this.baseElement.classList.remove(p('-opened'));
			document.documentElement.classList.remove(p('wrapper--is-closing'));
			document.documentElement.classList.remove(p('wrapper--is-blocking'));
			document.documentElement.classList.remove(p('wrapper--is-active'));

			this.baseElement.style.removeProperty('right');
			document.body.style.removeProperty('padding-right');

			this.state.opened = false;
			this.updateVisibility();
		}, TRANSITION_FALLBACK_DURATION);

		// Closing
		document.documentElement.classList.add(p('wrapper--is-closing'));
		document.documentElement.classList.remove(p('wrapper--is-opened'));

		updateToggleElements({...this.state, opened: false});
	}

	openParentPanel() {
		const parentPanelElement = getParentPanelElement(this.getOpenedPanelElement());
		if (parentPanelElement) {
			this.openPanel(parentPanelElement);
		}
	}

	setView(nextViewName) {
		console.log('setView', nextViewName);

		const previousViewName = this.state.view;
		if (previousViewName === nextViewName) {
			// setView', nextViewName, ' skipped
			return;
		}

		this.state.view = nextViewName;

		this.baseElement.classList.add(p(`-view-${nextViewName}`));
		document.documentElement.classList.add(p(`wrapper--view-${nextViewName}`));

		if (previousViewName) {
			this.baseElement.classList.remove(p(`-view-${previousViewName}`));
			document.documentElement.classList.remove(p(`wrapper--view-${previousViewName}`));

			if (nextViewName === VIEW_SEARCH) {
				this.searchPanel.openPanel();
			} else {
				if (this.initialPanelId) {
					this.openPanel(document.getElementById(this.initialPanelId), {animate: false});
				}
			}
		}

		updateToggleElements(this.state);
	}

	toggle(nextViewName = DEFAULT_VIEW) {
		console.log('toggle', nextViewName);

		if (nextViewName === this.state.view && this.state.opened) {
			this.close();
		} else {
			this.setView(nextViewName);
			this.open();
		}
	}

	async loadPanel(url) {
		if (!url) {
			throw new Error('missing url');
		}

		if (this.state.lazyLoadedPanels[url] === true) {
			// already done
			return undefined;
		}

		if (this.state.lazyLoadedPanels[url]) {
			// wait for pending load; return the existing promise
			return this.state.lazyLoadedPanels[url];
		}

		// load panel
		const promise = (async () => {
			try {
				const panels = await lazyLoadPanels(url);

				Object.keys(panels)
					.filter(id => !document.getElementById(id))
					.map(id => panels[id])
					.forEach(html => this.panelsContainerElement.insertAdjacentHTML('beforeend', html));

				this.state.lazyLoadedPanels[url] = true;

				// load panel resolved
				return undefined;
			} catch (e) {
				console.error('error while lazy loading panel', e);
				throw new Error('error loading'); // why re-throw a "stripped-down" error?
			}
		})();

		// store for later use
		this.state.lazyLoadedPanels[url] = promise;

		return promise;
	}

	updateVisibility() {
		const sidebarIsVisible = this.state.opened || breakpoint('sidebarFixed');
		setAriaAttribute(this.baseElement, 'hidden', !sidebarIsVisible);
		const allPanels = childrenWithClass(this.panelsContainerElement, p('panel'));
		allPanels.forEach(e => {
			const isVisible = sidebarIsVisible && e.classList.contains(p('panel--opened'));
			setAriaAttribute(e, 'hidden', !isVisible);
			setTabFocusable(e, isVisible);
		});
	}
}

function updateToggleElements({opened, view, id}) {
	const isMenuExpanded = opened && view === VIEW_MENU;
	updateToggleElement(
		pp('js', 'toggle'),
		isMenuExpanded,
		translate(isMenuExpanded ? 'sidebar.Close main navigation' : 'sidebar.Open main navigation'),
		id
	);
	updateToggleElement(
		pp('js', 'open-menu'),
		isMenuExpanded,
		translate(isMenuExpanded ? 'sidebar.Jump to main navigation' : 'sidebar.Open main navigation'),
		id
	);

	const isSearchExpanded = opened && view === VIEW_SEARCH;
	updateToggleElement(
		pp('js', 'search-toggle'),
		isSearchExpanded,
		translate(isSearchExpanded ? 'sidebar.Close search' : 'sidebar.Open search'),
		id
	);
}

function updateToggleElement(classNameBase, expanded, label, id) {
	[...document.getElementsByClassName(classNameBase)]
		.forEach(element => {
			element.classList[expanded ? 'add' : 'remove'](`${classNameBase}--active`);
			setAriaAttribute(element, 'haspopup', 'true');
			setAriaAttribute(element, 'controls', id);
			setAriaAttribute(element, 'expanded', expanded ? 'true' : 'false');
			setAriaAttribute(element, 'label', label);
		});
}

function getParentPanelElement(panelElement = null) {
	if (!panelElement) {
		return null;
	}

	return document.getElementById(panelElement.getAttribute(dP('parent')));
}

function lazyLoadPanels(url) {
	const lazyLoadUrl = url + '?lazy-load-sidebar';
	return fetchJsonPromise(lazyLoadUrl).then(json => json.data.panels);
}
